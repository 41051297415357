import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import {HttpClientModule} from '@angular/common/http';
import {KdmMapModule} from './kdm-map/kdm-map.module';
import {MyPrefs} from './MyPrefs';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule ,
    KdmMapModule
  ],
  providers: [MyPrefs],
  bootstrap: [AppComponent]
})
export class AppModule {

}

import {KdmMapComponent} from './kdm-map.component';
import {RetailerService} from '../retailer/retailer.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {MatAutocompleteModule, MatButtonModule, MatIconModule, MatInputModule} from '@angular/material';
import {AgmCoreModule} from '@agm/core';
import {NgModule} from '@angular/core';
import {MyPrefs} from '../MyPrefs';

@NgModule({
  declarations: [
    KdmMapComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatIconModule,
    MatButtonModule,
    MatAutocompleteModule,
    AgmCoreModule.forRoot({apiKey: 'AIzaSyCKbWPdK0RRTk1MXcZHZ1n-r2Gabc7ljQg' })
  ],
  exports: [KdmMapComponent],
  providers: [RetailerService],
  bootstrap: []
})
export class KdmMapModule {}

export class Retailer {
  id: string;
  name: string;
  ort: string;
  kanton: string;
  strasse: string;
  plz: string;
  telefon: string;
  telefax: string;
  www: string;
  email: string;
  publicEmail: string;
  lat: number;
  lng: number;
  veloplus: string;
  kdnr: string;

  static fromJSON(json: RetailerJSON): Retailer {
    let retailer = Object.create(Retailer.prototype);
    return Object.assign(retailer, json, {
      lat: parseFloat(json.lat),
      lng: parseFloat(json.lng)
    });
  }
  toJSON(): RetailerJSON {
    return Object.assign({} , this, {
      lat: this.lat.toString(),
      lng: this.lng.toString()
    });
  }

  link() {
    if (this.www == null) {
      return '';
    }
    if (this.www.indexOf('http') === 0) {
      return this.www;
    } else {
      return 'http://' + this.www;
    }
  }

  getPublicEmail(): string {
    return (this.publicEmail === null || this.publicEmail.trim() === '') ?
      this.email : this.publicEmail ;
  }

}

class RetailerJSON {
  id: string;
  name: string;
  ort: string;
  kanton: string;
  strasse: string;
  plz: string;
  telefon: string;
  telefax: string;
  www: string;
  email: string;
  lat: string;
  lng: string;
  veloplus: string;
  kdnr: string;
}

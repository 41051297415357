import {Component, ElementRef} from '@angular/core';
import {RetailerService} from './retailer/retailer.service';
import {MyPrefs} from './MyPrefs';
@Component({
  selector: 'app-kdm',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})

export class AppComponent  {

  constructor(private elem: ElementRef, private prefs: MyPrefs) {
    const channel = elem.nativeElement.getAttribute('data-channel');
    prefs.channel = channel;
  }



}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

export class MyPrefs {
  private _channel: string;
  private _stage: string;


  get channel() {
    return this._channel;
  }
  set channel(ch: string) {
    this._channel = ch;
  }


}

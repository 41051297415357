import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {Observable, of} from 'rxjs';
import {Retailer} from '../retailer/retailer';
import {map, startWith} from 'rxjs/operators';
import {RetailerService} from '../retailer/retailer.service';
import {e} from '@angular/core/src/render3';
import {element} from 'protractor';
import {MyPrefs} from '../MyPrefs';


@Component({
  selector: 'kdm-map',
  templateUrl: './kdm-map.component.html',
  styleUrls: ['./kdm-map.component.scss']
})

export class KdmMapComponent implements OnInit {
  @Input()
  zoom: number;
  @Input()
  lat: number;
  @Input()
  lng: number;
  retailes: Retailer[];
  filteredRetailer: Observable<Retailer[]>;
  myControl = new FormControl();
  openWindow: any;

  constructor (private service: RetailerService, private prefs: MyPrefs) {
  }

  ngOnInit(): void {
    this.retailes = this.service.getRetailes(this.prefs.channel);
    this.filteredRetailer = this.myControl.valueChanges.pipe(
      startWith(''), map(value => this._extra_filter(value))
    );
  }
  private _findRetailer(id: string): Retailer {
    return this.retailes.filter(r => r.id.toString() === id)[0];
  }

  private _extra_filter(value: string): Retailer[] {
    let foundRetailer: Retailer;
    if (value.toString().trim() === '') {
      this.zoom = 8;
      this.lat = 47.0000000;
      this.lng = 8.722855;
      this.openWindow = '';
      return this.retailes;
    } else {
      let searchArray: string[]; let foundArray: string[];
      searchArray = []; foundArray = [];
      this.retailes.forEach(r => searchArray.push(r.id + '   ' +
        r.name + '  ' + r.strasse + '  ' + r.kanton +
        '  ' + r.ort + '  ' + r.plz));
      let filterValue: string;
      filterValue = value.toString().toLowerCase();
      searchArray.filter(sRetailer => sRetailer.toLowerCase().includes(filterValue)).forEach(s => foundArray.push(s.split(' ')[0]));
      if (foundArray.length === 1) {
        foundRetailer = this._findRetailer(foundArray[0].toString().trim());
        this.openedWindow(foundRetailer);
        // this.zoomMap(foundRetailer);
      }
      return this.retailes.filter( r => foundArray.toString().includes(r.id));
    }

  }

  openedWindow(r: Retailer) {
    this.openWindow = r.id;
  }

  isInfoWindowOpen(id) {
    return this.openWindow === id;
  }

  zoomMap(r: Retailer) {
    this.lat = r.lat;
    this.lng = r.lng;
    this.zoom = 14;
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {catchError, retry} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {Retailer} from './retailer';

@Injectable ()
export class RetailerService {
  retailesUrl = `https://pim.komenda.ch/rest/hndl/list?apikey=24b06df1bb7d64de4cddeb531dbae78e30cd708c942f526af7a739732b291214`;

  constructor (private http: HttpClient) {

  }

  private getData(channel: string) {
    return this.http.get(this.retailesUrl + '&channel=' + channel)
      .pipe(
        retry(3), // retry a failed request up to 3 times
        catchError(this.handleError) // then handle the error
      );
  }
  getRetailes(channel: string): Retailer[] {
    let retailes: Retailer[];
    retailes = [];
    let newRetailer: Retailer;
    this.getData(channel).subscribe((data) => {
      // @ts-ignore
      if (data.hasOwnProperty('data') && data.data.hasOwnProperty('haendlerList') ) {
        // @ts-ignore
        data.data.haendlerList.forEach(e => {
          newRetailer = Retailer.fromJSON(e);
          if ( isNaN(newRetailer.lng)  || isNaN(newRetailer.lat)) {
            console.log ('The Retailer ' + Retailer.fromJSON(e).name +  ' have not Latitude or/and Longitude');
          } else {
            retailes.push(newRetailer);
          }
        });
      }});
    return retailes;
  }
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
